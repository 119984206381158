import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { convertCaseList, ConvertType } from 'utils/convertString'

import { IKDT, IKDTResponse } from '../types'

const fetchMyKDTApplyList = async (isSigned: boolean) => {
  let resp: AxiosResponse<any> | undefined
  if (isSigned) {
    resp = await axios.get<IKDTResponse[]>(`${window.apiEndpoint}/my/course/v1/kdt-applies`)
  }
  return resp ? (convertCaseList(resp?.data, ConvertType.CAMEL) as IKDT[]) : null
}

export const useMyKDTApplyList = (isSigned: boolean = true) => {
  return useQuery({
    queryKey: ['myKDTApplyList'],
    queryFn: () => fetchMyKDTApplyList(isSigned),
  })
}
