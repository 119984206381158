import styles from './Skeleton.module.scss'

const KdtSchoolSkeleton = () => {
  return (
    <div className="relative flex h-full w-full shrink-0 flex-col gap-4 overflow-hidden">
      <div
        className={`${styles.skeleton} relative aspect-square shrink-0 overflow-hidden rounded bg-gray-300 md:rounded-lg lg:aspect-[290/221]`}></div>
      <div className="box-border flex h-full w-full flex-col gap-4">
        <div className={`${styles.skeleton} h-[30px] w-23 overflow-hidden`}></div>
        <p className={`h-[30px] rounded ${styles.skeleton}`}></p>
        <div className="flex flex-col gap-2">
          <span className={`block h-[20px] rounded ${styles.skeleton}`}></span>
          <span className={`block h-[20px] w-8/12 rounded ${styles.skeleton}`}></span>
        </div>
        <p className={`h-[10px] w-1/2 rounded ${styles.skeleton}`}></p>
      </div>
    </div>
  )
}

export default KdtSchoolSkeleton
