interface IApplyPersonalStatusBadgeProps {
  // TODO: 미지원, 취소 상태 서버에 추가되면 추가처리
  statusLabel: string
}

const ApplyPersonalStatusBadge = ({ statusLabel }: IApplyPersonalStatusBadgeProps) => {
  return (
    <span
      className={
        'rounded-full border border-green-450 px-3 py-0.5 text-xs font-medium text-green-450 lg:text-sm lg:font-normal'
      }>
      {statusLabel}
    </span>
  )
}

export default ApplyPersonalStatusBadge
