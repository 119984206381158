import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { convertCaseList, ConvertType } from 'utils/convertString'

import { IContentList, IContentListResponse } from '../types/content'

export const fetchContentList = async () => {
  const res = await axios.get<IContentListResponse>(
    `${window.apiEndpoint}/showroom/v1/grounds?per_page=9999`,
  )

  return convertCaseList(res.data, ConvertType.CAMEL) as IContentList
}

export const useContentListQuery = () => {
  return useQuery({
    queryKey: ['contentList'],
    queryFn: fetchContentList,
    staleTime: Infinity,
    cacheTime: 60 * 60 * 1000,
    select: data => data.data,
  })
}
