import styles from './Skeleton.module.scss'

const MainBannerSkeleton = () => {
  return (
    <div className="relative flex h-[344px] w-full md:h-[400px]">
      <div className={`relative h-full w-full ${styles.skeleton} bg-gray-300`}></div>
    </div>
  )
}

export default MainBannerSkeleton
