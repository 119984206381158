import dayjs from 'dayjs'
import isSameOfAfter from 'dayjs/plugin/isSameOrAfter'
import checkMySimplifyKdtPeriod from 'features/apply/utils/checkMySimplifyKdtPeriod'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ApplyStatus } from 'stores/apply'

import { IKDT } from '../types'
import { checkMyKdtPeriod } from '../utils/checkMyKdtPeriod'

dayjs.extend(isSameOfAfter)
interface IDeadLineList {
  [periodStatus: string]: string | null
}

interface ICurrentAction {
  label: string
  handler: () => void
}

export const useCurrentMyKDTApplication = (myKdtApplicationList: IKDT[] | undefined) => {
  const router = useRouter()
  const [currentMyKDTApplication, setCurrentMyKDTApplication] = useState<IKDT | undefined>(
    undefined,
  )

  const isSimplifyKDT = useMemo(() => currentMyKDTApplication?.flags?.includes('new-kdt'), [
    currentMyKDTApplication,
  ])
  const isParticipationPeriod =
    currentMyKDTApplication &&
    dayjs().isSameOrAfter(currentMyKDTApplication?.kdtAttr?.participationStartedAt)

  const [applyStatus, setApplyStatus] = useState<string | null>(null)
  const [applyPeriodList, setApplyPeriodList] = useState<{ [periodStatus: string]: string } | null>(
    null,
  )
  const [currentApplyInfo, setCurrentApplyInfo] = useState<IApplyInfo | null>(null)
  const [currentApplyEndedAt, setCurrentApplyEndedAt] = useState<string | null>(null)
  const [currentApplyStartedAt, setCurrentApplyStartedAt] = useState<string | null>(null)
  const [currentAction, setCurrentAction] = useState<ICurrentAction | null>(null)
  const curDate = dayjs()
  const goToMyApplyStatus = () => router.push('/my/apply-status')
  const goToApply = (klassId: string) => router.push(`/school/${klassId}/apply`)
  const goToMyKlass = () => router.push('/my/courses')

  const getActionBtn = useCallback(
    (klassId: string, applyInfo: IApplyInfo, applyStatus: string) => {
      return applyInfo?.isFinished ||
        (isSimplifyKDT &&
          isParticipationPeriod &&
          (currentMyKDTApplication?.applies?.some(({ status }) => _.isEqual(status, 'spare')) ||
            (currentMyKDTApplication?.applies?.length > 1 &&
              currentMyKDTApplication?.applies?.every(({ status }) =>
                _.isEqual(status, 'passed'),
              ))))
        ? { label: '현황 확인하기', handler: goToMyApplyStatus }
        : {
            // 참가확인서 심사기간에도 참가확인서 지원기간과 동일한 프로세스
            label: _.isEqual(applyStatus, 'participationReview')
              ? applyInfo.status.includes('inited')
                ? currentApplyLabelList['participationStart']
                : currentApplyLabelList['participation']
              : applyInfo.status.includes('inited')
              ? currentApplyLabelList[`${applyStatus}Start`]
              : currentApplyLabelList[applyStatus],
            handler: () =>
              applyStatus.includes('Review')
                ? _.isEqual(applyStatus, 'participationReview')
                  ? goToApply(klassId)
                  : goToMyApplyStatus()
                : goToApply(klassId),
          }
    },
    [goToApply, goToMyApplyStatus, currentApplyInfo],
  )

  useEffect(() => {
    if (!_.isEmpty(myKdtApplicationList)) {
      const curApply = myKdtApplicationList?.filter(({ applyStartedAt, startedAt }) =>
        curDate.isBetween(applyStartedAt, startedAt),
      )[0]
      setCurrentMyKDTApplication(curApply)
    }
  }, [curDate, myKdtApplicationList])

  useEffect(() => {
    if (currentMyKDTApplication) {
      const { startedAt, endedAt, applyStartedAt, applyEndedAt, kdtAttr } = currentMyKDTApplication
      const periodList = {
        startedAt: startedAt as string,
        applyStartedAt,
        applyEndedAt,
        assignmentStartedAt: kdtAttr?.assignmentStartedAt as string,
        assignmentEndedAt: kdtAttr?.assignmentEndedAt as string,
        participationStartedAt: kdtAttr?.participationStartedAt as string,
        participationEndedAt: kdtAttr?.participationEndedAt as string,
        endedAt: endedAt as string,
      }
      setApplyPeriodList(periodList)

      const klassApplyStatus = isSimplifyKDT
        ? checkMySimplifyKdtPeriod({
            startedAt: periodList?.startedAt,
            assignmentStartedAt: periodList?.assignmentStartedAt,
            assignmentEndedAt: periodList?.assignmentEndedAt,
            participationStartedAt: periodList?.participationStartedAt,
            participationEndedAt: periodList?.participationEndedAt,
            endedAt: periodList?.endedAt,
          })
        : checkMyKdtPeriod(periodList)

      setApplyStatus(klassApplyStatus)

      const initialApplyInfo: IDefaultApplyInfoList = {}
      const serverApplyInfoList = _.reduce(
        defaultApplyInfoList,
        (acc, cur, key) => {
          const serverApplyInfo = currentMyKDTApplication.applies.find(({ applyType }) =>
            key.includes(applyType),
          )

          if (_.isEqual(key, klassApplyStatus) && serverApplyInfo) {
            acc[key] = {
              status: serverApplyInfo?.status,
              isFinished: serverApplyInfo?.isFinished,
            }

            return acc
          }

          acc[key] = cur

          return acc
        },
        initialApplyInfo,
      )
      const applyInfoList = { ...defaultApplyInfoList, ...serverApplyInfoList }
      const applyInfo = applyInfoList[klassApplyStatus]
      setCurrentApplyInfo(applyInfo)

      const getApplyAction = _.isEqual(klassApplyStatus, 'ing')
        ? {
            label: '학습하러 가기',
            handler: goToMyKlass,
          }
        : getActionBtn(currentMyKDTApplication.id, applyInfo, klassApplyStatus)
      setCurrentAction(getApplyAction)
    }
  }, [currentMyKDTApplication])

  useEffect(() => {
    if (applyStatus && applyPeriodList) {
      const deadLineList: IDeadLineList = {
        applyStartedAt: applyPeriodList.applyStartedAt, // 1차 지원기간 시작날짜
        applyReviewStartedAt: applyPeriodList.applyEndedAt, // 1차 심사기간 시작날짜
        apply: applyPeriodList.applyEndedAt, // 1차 지원기간 종료날짜
        applyReview: applyPeriodList.assignmentStartedAt, // 1차 심사기간 종료날짜
        assignmentStartedAt: applyPeriodList.assignmentStartedAt,
        assignmentReviewStartedAt: applyPeriodList.assignmentEndedAt,
        assignment: applyPeriodList.assignmentEndedAt,
        assignmentReview: applyPeriodList.participationStartedAt,
        participationStartedAt: applyPeriodList.participationStartedAt,
        participation: applyPeriodList.participationEndedAt,
        participationReviewStartedAt: applyPeriodList.participationEndedAt,
        participationReview: applyPeriodList.startedAt,
        ing: null,
      }
      setCurrentApplyEndedAt(
        applyStatus.includes('Review') ? deadLineList[applyStatus] : deadLineList[applyStatus],
      )
      setCurrentApplyStartedAt(deadLineList[`${applyStatus}StartedAt`])
    }
  }, [applyPeriodList, applyStatus])

  return {
    currentMyKDTApplication,
    applyStatus,
    currentApplyEndedAt,
    currentApplyStartedAt,
    currentApplyInfo,
    currentAction,
  }
}

interface IApplyInfo {
  status: ApplyStatus
  isFinished: boolean
}

interface IDefaultApplyInfoList {
  [applyStatus: string]: IApplyInfo
}

const defaultApplyInfoList: IDefaultApplyInfoList = {
  apply: {
    status: 'pending',
    isFinished: false,
  },
  applyReview: {
    status: 'pending',
    isFinished: false,
  },
  assignment: {
    status: 'inited',
    isFinished: false,
  },
  assignmentReview: {
    status: 'pending',
    isFinished: false,
  },
  participation: {
    status: 'inited',
    isFinished: false,
  },
  participationReview: {
    status: 'inited',
    isFinished: false,
  },
}

interface ICurrentApplyLabelList {
  [applyStatus: string]: string
}

const currentApplyLabelList: ICurrentApplyLabelList = {
  apply: '이어서 작성하기',
  applyReview: '현황 확인하기',
  assignmentStart: '2차 과제 제출하기',
  assignment: '이어서 작성하기',
  assignmentReview: '현황 확인하기',
  participationStart: '최종 참가 확인서',
  participation: '이어서 작성하기',
  participationReview: '현황 확인하기',
}
