import { useMediaQuery } from '@mantine/hooks'
import dayjs from 'dayjs'
import isSameOfAfter from 'dayjs/plugin/isSameOrAfter'
import _ from 'lodash'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useCallback, useMemo } from 'react'
import { MyKDTApplyStatus } from 'stores/apply'

import styles from './ApplyPersonalCard.module.scss'
import ApplyPersonalStatusBadge from './ApplyPersonalStatusBadge'

require('dayjs/locale/ko')
dayjs.extend(isSameOfAfter)
interface IApplyPersonalCardProps {
  title: string
  image: string
  endedAt: string | null
  startedAt: string | null
  participationStartedAt: string | null
  applyInfo: MyKDTApplyStatus[]
  applyAction: {
    label: string
    handler: () => void
  }
  isIngKlass: boolean
  courseId: string
  imageM: string
  applyStatus: string
  isSimplifyKDT: boolean
}

const ApplyPersonalCard = ({
  title,
  image,
  endedAt,
  startedAt,
  participationStartedAt,
  applyInfo,
  applyAction,
  isIngKlass,
  courseId,
  imageM,
  applyStatus,
  isSimplifyKDT,
}: IApplyPersonalCardProps) => {
  const curDate = dayjs()
  const { label, handler } = applyAction
  const applyEndedAt = dayjs(endedAt).locale('ko').format('YY.MM.DD a h시')
  const applyStartedAt = dayjs(startedAt).locale('ko').format('YY.MM.DD a h시')
  const nowDate = dayjs().format('YYYY-MM-DDTHH:mm:ss.000ZZ')
  const expirationDate = useMemo(() => dayjs(endedAt).diff(nowDate, 'day'), [endedAt])
  const isMobile = useMediaQuery('(max-width: 1023px)')
  const isReviewPeriod = _.includes(applyStatus, 'Review')
  const router = useRouter()
  const goToDetailPage = useCallback(
    () => router.push(`/school/${courseId}`).then(() => window.scrollTo(0, 0)),
    [courseId, router],
  )

  const statusLabel = useMemo(() => {
    const isParticipationPeriod = curDate.isSameOrAfter(participationStartedAt)
    if (isSimplifyKDT) {
      if (isParticipationPeriod && applyInfo?.some(({ status }) => _.isEqual(status, 'spare'))) {
        return '예비합격'
      } else if (
        isParticipationPeriod &&
        applyInfo?.length > 1 &&
        applyInfo?.every(({ status }) => _.isEqual(status, 'passed'))
      ) {
        return '합격'
      } else {
        return isReviewPeriod ? '심사중' : '진행중'
      }
    } else {
      return isReviewPeriod ? '심사중' : '진행중'
    }
  }, [applyInfo, curDate, isReviewPeriod, isSimplifyKDT, participationStartedAt])

  return (
    <div
      className={`${styles.cardWrap} mt-6 flex flex-col justify-between overflow-hidden rounded-lg border border-gray-300 lg:mt-8 lg:flex-row`}>
      <div className="flex flex-col lg:flex-row">
        <header className="relative aspect-[2/1] lg:h-[200px] lg:w-[200px]">
          <Image
            src={isMobile ? imageM : image}
            alt={title}
            width="200"
            height="200"
            layout="fill"
            objectFit="contain"
            className="w-full lg:h-[200px] lg:w-[200px]"
          />
        </header>
        <main className={'my-auto flex flex-col justify-center p-5 lg:pl-6'}>
          <dl>
            <dt className="flex items-center space-x-2">
              <p className="text-base font-semibold lg:text-xl lg:font-bold">{title}</p>
              {/* <span
                className={`border rounded-3xl py-0.5 px-2.5 lg:py-1 lg:px-3 text-xs lg:text-base font-medium lg:font-semibold ${
                  expirationDate < 4
                    ? 'text-red-450 border-red-450'
                    : 'text-gray-800 border-gray-800'
                } `}>
                D-{expirationDate === 0 ? 'day' : expirationDate}
              </span> */}
              <ApplyPersonalStatusBadge statusLabel={statusLabel as string} />
            </dt>
            {!isIngKlass && (
              <dd className="mt-1.5 text-sm text-gray-500 lg:mt-2 lg:text-base lg:font-medium">
                {isReviewPeriod ? '심사' : '지원'}기간
                <span className="mx-1.5 hidden md:inline-block">|</span> {applyStartedAt} ~{' '}
                {applyEndedAt}
              </dd>
            )}
          </dl>
        </main>
      </div>
      <footer className="flex w-full flex-row-reverse px-5 pb-5 md:self-center lg:w-auto lg:flex-col lg:space-y-3 lg:p-0 lg:pr-6">
        <button
          onClick={handler}
          className={`${styles.goToBtn} inline-flex items-center justify-center rounded-md bg-yellow-450 py-3 lg:rounded-lg lg:py-4`}>
          <span className="text-sm font-semibold text-white xl:text-lg">{label}</span>
        </button>
        <button
          onClick={goToDetailPage}
          className={`${styles.goToBtn} y-3 mr-2 inline-flex items-center justify-center rounded-md border border-yellow-450 lg:mr-0 lg:rounded-lg lg:py-4`}>
          <span className="text-sm font-semibold text-yellow-450 xl:text-lg">상세페이지</span>
        </button>
      </footer>
    </div>
  )
}
export default ApplyPersonalCard
