import styles from './Skeleton.module.scss'

const KdcSchoolSkeleton = () => {
  return (
    <div className="relative flex h-full w-full shrink-0 flex-col gap-4 overflow-hidden">
      <div
        className={`${styles.skeleton} relative aspect-square shrink-0 overflow-hidden rounded bg-gray-300 md:rounded-lg lg:aspect-[2/1]`}></div>
      <div className="box-border flex h-full w-full flex-col gap-4">
        <div className={`${styles.skeleton} h-[30px] w-23 overflow-hidden`}></div>
        <p className={`h-[25px] rounded ${styles.skeleton}`}></p>
        <p className={`h-[25px] w-3/4 rounded ${styles.skeleton}`}></p>
        <p className={`h-[10px] w-1/2 rounded ${styles.skeleton}`}></p>
      </div>
    </div>
  )
}

export default KdcSchoolSkeleton
