import dayjs from 'dayjs'
import { IContent } from 'features/showroom/types/content'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

interface IContentItemProps {
  content: IContent
  isMain?: boolean
}

const ContentCard = ({ content, isMain = false }: IContentItemProps) => {
  const { id, title, subtitle, views, publishedAt, thumbnail } = content
  const router = useRouter()

  const goToDetail = useCallback(() => {
    return router.push(`/content/${id}`)
  }, [id, router])

  return (
    <div className="flex cursor-pointer flex-col" onClick={goToDetail}>
      <div className="relative w-full overflow-hidden rounded-lg">
        <Image
          src={thumbnail}
          alt={title}
          className="object-cover"
          width={400}
          height={200}
          layout={'responsive'}
        />
        {!isMain && (
          <div className="group absolute left-0 top-0 flex h-full w-full items-center justify-center bg-black/50 text-white transition-all hover:bg-transparent">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-auto w-10 text-gray-100 group-hover:hidden">
              <path
                fillRule="evenodd"
                d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        )}
      </div>
      <div className="flex flex-col py-4 lg:py-5">
        <p className="mb-1.5 text-lg font-semibold lg:mb-2 lg:text-xl">{title}</p>
        <span className="mb-2 break-words text-sm text-gray-500 lg:text-base">{subtitle}</span>
        <span className="text-xs text-gray-500 lg:text-sm">
          {views.toLocaleString('en-us')} views &#183; {dayjs(publishedAt).format('YYYY.MM.DD')}
        </span>
      </div>
    </div>
  )
}
export default ContentCard
