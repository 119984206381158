import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { convertCaseList, ConvertType } from 'utils/convertString'

import { IBanner, IBannerResponse } from '../types/banner'

export const fetchBannerList = async () => {
  const res = await axios.get<IBannerResponse[]>(`${window.apiEndpoint}/showroom/v1/banners`)

  return convertCaseList(res.data, ConvertType.CAMEL) as IBanner[]
}

export const useBannerListQuery = () => {
  return useQuery({
    queryKey: ['bannerList'],
    queryFn: fetchBannerList,
    staleTime: Infinity,
    cacheTime: 60 * 60 * 1000,
  })
}
