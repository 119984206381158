import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetween from 'dayjs/plugin/isBetween'
import isSameOfAfter from 'dayjs/plugin/isSameOrAfter'
dayjs.extend(customParseFormat)
dayjs.extend(isBetween)
dayjs.extend(isSameOfAfter)

export interface IPeriodList {
  [period: string]: string
}

export const checkMyKdtPeriod = (periodList: IPeriodList) => {
  const {
    startedAt,
    applyStartedAt,
    applyEndedAt,
    assignmentStartedAt,
    assignmentEndedAt,
    participationStartedAt,
    participationEndedAt,
    endedAt,
  } = periodList

  const curDate = dayjs()
  const isPreAlertPeriod = curDate.isBefore(
    dayjs(applyStartedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
  )
  const isApplyPeriod = curDate.isBetween(
    dayjs(applyStartedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
    dayjs(applyEndedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
  )
  const isApplyReviewPeriod = curDate.isBetween(
    dayjs(applyEndedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
    dayjs(assignmentStartedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
  )

  const isAssignmentPeriod = curDate.isBetween(
    dayjs(assignmentStartedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
    dayjs(assignmentEndedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
  )

  const isAssignmentReviewPeriod = curDate.isBetween(
    dayjs(assignmentEndedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
    dayjs(participationStartedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
  )

  const isParticipationPeriod = curDate.isBetween(
    dayjs(participationStartedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
    dayjs(participationEndedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
  )

  const isParticipationReviewPeriod = curDate.isBetween(
    dayjs(participationEndedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
    dayjs(startedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
  )
  const isKDTPeriod = curDate.isBetween(
    dayjs(startedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
    dayjs(endedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
  )

  if (isPreAlertPeriod) {
    return 'early'
  }

  if (isApplyPeriod) {
    return 'apply'
  }

  if (isApplyReviewPeriod) {
    return 'applyReview'
  }

  if (isAssignmentPeriod) {
    return 'assignment'
  }

  if (isAssignmentReviewPeriod) {
    return 'assignmentReview'
  }

  if (isParticipationPeriod) {
    return 'participation'
  }

  if (isParticipationReviewPeriod) {
    return 'participationReview'
  }

  if (isKDTPeriod) {
    return 'ing'
  }

  return 'end'
}
