import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import { useMediaQuery } from '@mantine/hooks'
import { IBanner } from 'features/showroom/types/banner'
import { isNull } from 'lodash'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { MouseEvent, useCallback } from 'react'

import styles from './BannerItem.module.scss'

interface IArrowProps {
  onClick: (e: MouseEvent<HTMLDivElement>) => void
}

const PrevArrow = ({ onClick }: IArrowProps) => {
  return (
    <div className="mx-2 hidden cursor-pointer lg:block" onClick={onClick}>
      <ChevronLeftIcon className="h-4 w-4" />
    </div>
  )
}

const NextArrow = ({ onClick }: IArrowProps) => {
  return (
    <div className="hidden cursor-pointer lg:block" onClick={onClick}>
      <ChevronRightIcon className="h-4 w-4" />
    </div>
  )
}

interface IBannerItemProps {
  order: number
  listSize: number
  bannerItem: IBanner
  goToNextBanner: (e: MouseEvent<HTMLDivElement>) => void
  goToPrevBanner: (e: MouseEvent<HTMLDivElement>) => void
}

const HeroBannerItem = ({
  order,
  listSize,
  bannerItem,
  goToNextBanner,
  goToPrevBanner,
}: IBannerItemProps) => {
  const router = useRouter()
  const { title, subtitle, imgSrc, textColor, url, type } = bannerItem
  const isLaptop = useMediaQuery('(min-width: 1024px) and (max-width: 1279px)')
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1023px)')
  const isMobile = useMediaQuery('(max-width: 767px)')
  const { sm, md, lg, xl } = imgSrc
  const getBgImg = () => {
    if (isMobile) {
      return sm
    }

    if (isTablet) {
      return md
    }

    if (isLaptop) {
      return lg
    }

    return xl
  }

  const goToUrl = useCallback(() => {
    const isInternalType = type === 'internal'
    return isInternalType ? router.push(url) : window.open(url)
  }, [router, type, url])

  return (
    <div className="cursor-pointer" onClick={goToUrl}>
      <div className={styles.hero}>
        <div className="absolute left-0 top-0 z-0 h-full w-full bg-gray-100">
          <Image src={getBgImg()} alt="메인배너" layout="fill" objectFit="cover" priority={true} />
        </div>
        <div className="relative z-30 mx-auto flex h-full max-w-7xl px-4 lg:pl-6">
          <div className="mb-6 self-end pr-4 md:mb-0 md:self-center">
            <h2
              className={`${styles.title} break-words font-bold`}
              style={!isNull(textColor) ? { color: textColor } : {}}>
              {title}
            </h2>
            <h3
              className={`${styles.subTitle} mt-2 break-keep text-sm lg:text-base xl:text-lg`}
              style={!isNull(textColor) ? { color: textColor } : {}}>
              {subtitle}
            </h3>
          </div>
          <div className="z-100 mb-6 ml-auto mt-auto flex shrink-0 items-center justify-center self-end rounded-2xl bg-black/30 px-3 py-1 text-white md:absolute md:bottom-6 md:mb-0 md:ml-0 md:self-start">
            <span className="text-xs font-normal">
              {order} / {listSize}
            </span>
            <PrevArrow onClick={goToPrevBanner} />
            <NextArrow onClick={goToNextBanner} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroBannerItem
