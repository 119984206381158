import dayjs from 'dayjs'

import { IPeriodList } from './checkMyKdtPeriod'

const checkMySimplifyKdtPeriod = ({
  startedAt,
  assignmentStartedAt,
  assignmentEndedAt,
  participationStartedAt,
  participationEndedAt,
  endedAt,
}: IPeriodList) => {
  const curDate = dayjs()

  const isPreAlertPeriod = curDate.isBefore(
    dayjs(assignmentStartedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
  )

  const isAssignmentPeriod = curDate.isBetween(
    dayjs(assignmentStartedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
    dayjs(assignmentEndedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
  )

  const isAssignmentReviewPeriod = curDate.isBetween(
    dayjs(assignmentEndedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
    dayjs(participationStartedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
  )

  const isParticipationPeriod = curDate.isBetween(
    dayjs(participationStartedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
    dayjs(participationEndedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
  )

  const isParticipationReviewPeriod = curDate.isBetween(
    dayjs(participationEndedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
    dayjs(startedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
  )
  const isKDTPeriod = curDate.isBetween(
    dayjs(startedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
    dayjs(endedAt, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format(),
  )

  if (isPreAlertPeriod) {
    return 'early'
  }

  if (isAssignmentPeriod) {
    return 'assignment'
  }

  if (isAssignmentReviewPeriod) {
    return 'assignmentReview'
  }

  if (isParticipationPeriod) {
    return 'participation'
  }

  if (isParticipationReviewPeriod) {
    return 'participationReview'
  }

  if (isKDTPeriod) {
    return 'ing'
  }

  return 'end'
}

export default checkMySimplifyKdtPeriod
