import { ChevronRightIcon } from '@heroicons/react/outline'
import { useMediaQuery } from '@mantine/hooks'
import _ from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'

import styles from './HomeCTACard.module.scss'

interface IHomeCTACardProps {
  title: string
  subTitle: string
  url: string
  bgImg: IBgImgProps
}
interface IBgImgProps {
  pc: string
  tablet: string | null
  mobile: string | null
  laptop: string | null
}

const HomeCTACard = ({ title, subTitle, url, bgImg }: IHomeCTACardProps) => {
  const isMobile = useMediaQuery('(max-width: 767px)')
  const isTablet = useMediaQuery('(min-width:768px) and (max-width:1023px)')
  const isLaptop = useMediaQuery('(min-width:1024px) and (max-width:1279px)')
  const { pc, mobile, tablet, laptop } = bgImg
  const renderBgImg = () => {
    if (isMobile && !_.isNull(mobile)) return mobile
    else if (isTablet && !_.isNull(tablet)) return tablet
    else if (isLaptop && !_.isNull(laptop)) return laptop
    else return pc
  }
  return (
    <Link href={url} as={url}>
      <a className={`${styles.EducationCard} relative bg-gray-50 px-4 py-6 md:bg-gray-100 lg:p-10`}>
        <div className="absolute left-0 top-0 z-0 h-full w-full">
          <Image src={renderBgImg()} alt={title} layout="fill" objectFit="cover" />
        </div>
        <div className="relative z-10">
          <h4 className="text-lg font-bold leading-tight lg:text-2xl">{title}</h4>
          <p className="mt-1 whitespace-pre-wrap text-sm font-normal text-gray-800 md:text-base">
            {subTitle}
          </p>
          <button className="mt-4 inline-flex items-center justify-center font-semibold text-gray-500">
            <span className="text-sm">더 알아보기</span>
            <ChevronRightIcon width={16} className="ml-1 text-gray-500 md:ml-2" />
          </button>
        </div>
      </a>
    </Link>
  )
}

export default HomeCTACard
