import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { IBanner } from 'features/showroom/types/banner'
import { chain } from 'lodash'
import { MouseEvent, useEffect, useRef } from 'react'
import Slider, { Settings } from 'react-slick'

import BannerItem from './BannerItem'

interface IBannerProps {
  bannerList: IBanner[]
}

const Banner = ({ bannerList }: IBannerProps) => {
  const randomIdx = chain(bannerList).size().range().shuffle().head().value()

  const settings: Settings = {
    dots: false,
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 4000,
    touchMove: true,
    arrows: false,
    className: 'relative mx-auto overflow-hidden',
  }

  const listSize = bannerList.length
  const bannerRef = useRef<Slider>(null)
  const goToNextBanner = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    bannerRef?.current?.slickNext()
  }
  const goToPrevBanner = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    bannerRef?.current?.slickPrev()
  }

  useEffect(() => {
    if (bannerRef.current) {
      bannerRef.current.slickGoTo(randomIdx)
    }
  }, [randomIdx])

  return (
    <Slider {...settings} ref={bannerRef} className="hero-banner">
      {bannerList.map((bannerItem, idx) => (
        <BannerItem
          key={`carousel_item_${idx}`}
          bannerItem={bannerItem}
          order={idx + 1}
          listSize={listSize}
          goToNextBanner={goToNextBanner}
          goToPrevBanner={goToPrevBanner}
        />
      ))}
    </Slider>
  )
}

export default Banner
